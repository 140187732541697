import React from 'react'

import { Layout } from 'components/global'
import {
  Banner,
  CastList,
  Gallery,
  Hero,
  ProductionList,
  Social,
  Tickets,
  YourVisit,
} from 'components/blocks'

const IndexPage = () => (
  <Layout pageName="homepage">
    <Hero />
    <Banner />
    <Tickets />
    <YourVisit />
    <Gallery />
    <CastList type="cast" />
    <CastList type="creative" />
    <ProductionList />
    <Social />
  </Layout>
)

export default IndexPage
